var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "progress-container" } },
    [
      _vm.loaded
        ? [
            _c(
              "ul",
              { staticClass: "progress" },
              [
                _c("li", { staticClass: "progress-step" }, [
                  _c(
                    "div",
                    {
                      staticClass: "details",
                      class: _vm.completeCheckmark("new"),
                    },
                    [
                      _c("p", { staticClass: "date" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.getDate(_vm.currentObject?.created_at)) +
                            "\n          "
                        ),
                      ]),
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.filingOrderReceived) +
                            "\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "expected" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.expectedProcessTime) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                ]),
                [
                  _vm.pendingClientActions.includes(_vm.currentFilingStatus)
                    ? _c("li", { staticClass: "progress-step" }, [_vm._m(0)])
                    : _vm._e(),
                ],
                _c("li", { staticClass: "progress-step" }, [
                  _c(
                    "div",
                    {
                      staticClass: "details",
                      class: _vm.completeCheckmark("submitted-to-state"),
                    },
                    [
                      _c("h3", { staticClass: "title no-dates-upper" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.submittedToStateName) +
                            "\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "expected" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.expectedStateProcessTime) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                ]),
                [
                  !_vm.cancelledStatuses.includes(_vm.currentFilingStatus)
                    ? _c("li", { staticClass: "progress-step" }, [
                        _c(
                          "div",
                          {
                            staticClass: "details",
                            class: _vm.completeCheckmark("completed"),
                          },
                          [
                            _c("h3", { staticClass: "title no-dates-upper" }, [
                              _vm._v(
                                "\n              Filing Completed\n            "
                              ),
                            ]),
                            _c("p", { staticClass: "expected" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.expectedCompletionDate) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _c("li", { staticClass: "progress-step" }, [
                        _c(
                          "div",
                          {
                            staticClass: "details",
                            class: _vm.completeCheckmark("completed"),
                          },
                          [
                            _c("h3", { staticClass: "title no-dates" }, [
                              _vm._v(
                                "\n              Filing Cancelled\n            "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                ],
              ],
              2
            ),
          ]
        : _c("ct-centered-spinner"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details current" }, [
      _c("h3", { staticClass: "title no-dates" }, [
        _vm._v("\n              Client Action Required\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }