<template>
  <div id="progress-container">
    <template v-if="loaded">
      <ul class="progress">

        <li class="progress-step">
          <div
            class="details"
            :class="completeCheckmark('new')"
          >
            <p class="date">
              {{ getDate(currentObject?.created_at) }}
            </p>
            <h3 class="title">
              {{ filingOrderReceived }}
            </h3>
            <p class="expected">
              {{ expectedProcessTime }}
            </p>
          </div>
        </li>

        <template>
          <li v-if="pendingClientActions.includes(currentFilingStatus)" class="progress-step">
            <div class="details current">
              <h3 class="title no-dates">
                Client Action Required
              </h3>
            </div>
          </li>
        </template>

        <li class="progress-step">
          <div
            class="details"
            :class="completeCheckmark('submitted-to-state')"
          >
            <h3 class="title no-dates-upper">
              {{ submittedToStateName }}
            </h3>
            <p class="expected">
              {{ expectedStateProcessTime }}
            </p>
          </div>
        </li>

        <template>
          <li v-if="!cancelledStatuses.includes(currentFilingStatus)" class="progress-step">
            <div
              class="details"
              :class="completeCheckmark('completed')"
            >
              <h3 class="title no-dates-upper">
                Filing Completed
              </h3>
              <p class="expected">
                {{ expectedCompletionDate }}
              </p>
            </div>
          </li>
          <li v-else class="progress-step">
            <div
              class="details"
              :class="completeCheckmark('completed')"
            >
              <h3 class="title no-dates">
                Filing Cancelled
              </h3>
            </div>
          </li>
        </template>
      </ul>
    </template>
    <ct-centered-spinner v-else />
  </div>
</template>
<script>
import { formatStandardDateFromIso } from '@/common/modules/formatters'

export default {
  name: 'FilingOrderProgress',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
    cancelledStatuses: {
      type: Array,
      default: () => [],
    },
    currentFilingStatus: {
      type: String,
      default: '',
    },
    filingOrderStatuses: {
      type: Array,
      default: () => [],
    },
    jurisdictionName: {
      type: String,
      default: '',
    },
    product: {
      type: Object,
      default: () => {},
    },
    currentObject: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loaded: false,
      filingMethod: {},
      currentFilingStatusIndex: null,
      pendingClientActions: [],
    }
  },
  computed: {
    filingOrderReceived() {
      return `${this.product.name} Filing Received`
    },
    expectedCompletionDate() {
      const internalProcessingTime = this.filingMethod?.internal_processing_time?.days
      const stateProcessingTime = this.filingMethod?.filed_in?.days

      if (!internalProcessingTime && !stateProcessingTime) return 'Expected Filed By: TBD'

      const totalDays = internalProcessingTime + stateProcessingTime
      const orderItemCreation = new Date(this.currentObject?.created_at)
      const epochStartDate = orderItemCreation.getTime()
      const expectedCompletionDate = this.calculateDateFromBusinessDays(epochStartDate, totalDays)

      return `Expected Filed By: ${expectedCompletionDate}`
    },
    expectedProcessTime() {
      const expectedDays = this.filingMethod?.internal_processing_time?.days || 'TBD'

      return `Expected Processing Time: ${this.pluralizeDays(expectedDays)}`
    },
    expectedStateProcessTime() {
      let expectedDays = this.filingMethod?.filed_in?.days || 0
      if (expectedDays === 0 && this.product?.filing_method?.filed_in?.hours === 24) expectedDays = 'Same'

      return `Expected State Processing Time: ${this.pluralizeDays(expectedDays)}`
    },
    submittedToStateName() {
      return `Submitted to State of ${this.jurisdictionName}`
    },
  },
  async mounted() {
    this.loaded = true
    this.filingMethod = this.product?.filing_method
    this.currentFilingStatusIndex = this.getStatusTrackingIndex(this.currentFilingStatus)
    this.pendingClientActions = this.buildPendingActions('pendingClientActions')
  },
  methods: {
    buildPendingActions(filingCondition) {
      return this.filingOrderStatuses[1][filingCondition]
    },
    calculateDateFromBusinessDays(epochStartDate, totalDays) {
      let futureDate = new Date(epochStartDate)

      if (this.isWeekend(futureDate)) futureDate = this.nextBusinessDay(futureDate)

      while (totalDays > 0) {
        futureDate = this.nextBusinessDay(futureDate)
        totalDays--
      }

      return formatStandardDateFromIso(futureDate)
    },
    completeCheckmark(status) {
      const statusIndex = this.getStatusTrackingIndex(status)

      return this.currentFilingStatusIndex >= statusIndex ? 'completed' : ''
    },
    getDate(isoDate) {
      return formatStandardDateFromIso(isoDate) || ''
    },
    getStatusTrackingIndex(status) {
      let statusIndex = 2
      const statusParam  = status

      this.filingOrderStatuses.forEach((category, index) => {
        for (const [ _point, values ] of Object.entries(category)) {
          if (values && values.includes(statusParam)) statusIndex = index
        }
      })

      return statusIndex
    },
    isWeekend(date) {
      return [0, 6].includes(date.getDay())
    },
    nextBusinessDay(date) {
      const nextDay = new Date(date.getTime())
      const nextMonday = new Date(date.getTime())

      nextDay.setDate(date.getDate() + 1)
      nextMonday.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7)

      return this.isWeekend(nextDay) ? nextMonday : nextDay
    },
    pluralizeDays(expectedDays) {
      let formattedExpectedDays = `${expectedDays} day`

      if (expectedDays !== 'Same' && expectedDays !== 1) formattedExpectedDays = `${formattedExpectedDays}s`
      else if (expectedDays === 'TBD') formattedExpectedDays = expectedDays

      return formattedExpectedDays
    },
  },
}
</script>
<style lang="scss" scoped>
// Circle with Check
$circle-size-small: 16px;
$circle-size-large: 30px;
$circle-position-top: 35px;   // anchor point (top)  for line and filled-in circle with icon
$circle-position-left: -15px; // anchor point (left) for line and filled-in circle with icon
$icon-check: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"/></svg>';

// Palette
$violet2: #000864;

// Typography
// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: 400, $color: $violet2) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $violet2;
}

// Typography: Font
h3 {
  @include typography(
    $font-size: 1.5em,
    $font-weight: $ct-ui-font-weight-7
  );
}

p {
  @include typography();
}

// Mixin: Circles
@mixin circle(
  $background: white,
  $top: $circle-position-top,
  $left: $circle-position-left,
  $circle-size: $circle-size-large
) {
  content: '';
  position: absolute;
  width: $circle-size;
  height: $circle-size;
  top: $top;
  left: $left;
  background-color: $background;
  border-radius: 50%;
  border: 3px solid $violet2;
}

// Normalize
* {
  padding: 0;
  margin: 0;
}

// Component
#progress-container {
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  ul.progress {
    width: 100%;
    height: 100%;
    list-style: none;
    background: inherit;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    li.progress-step {
      margin: 0 2.0em;
      padding: 0.625em;
      position: relative;
      height: 140px;
      box-sizing: border-box;

      &:before {
        content: '';
        position: absolute;
        width: 0.125em;
        height: 100%;
        top: calc(#{$circle-position-top + 25px});
        left: calc(#{$circle-position-left + 14px});
        background: $violet2;
      }

      &:after {
        @include circle($background: white);
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;

        &:before {
          width: 0;
        }
      }

      div.details {
        padding: 1.0em 2.0em;
        position: relative;

        &.current {
          position: relative;

          &:before {
            @include circle(
              $background: $violet2,
              $top: calc(#{$circle-position-top} - 1px),
              $left: calc(#{$circle-position-left} - 1px),
              $circle-size: $circle-size-small
            );
            z-index: 1000;
          }
        }

        &.completed {
          position: relative;

          &:before {
            @include circle(
              $background: $violet2,
              $top: calc(#{$circle-position-top} - 8px),
              $left: calc(#{$circle-position-left} - 8px)
            );
            z-index: 1000;
          }

          &:after {
            content: url($icon-check);
            position: absolute;
            top: calc(#{$circle-position-top} - 1px);
            left: calc(#{$circle-position-left} + 1px);
            z-index: 1100;
          }
        }

        h3.title {
          margin: 0.2em 0;
        }

        .no-dates {
          padding: 1.0em 0;
        }

        .no-dates-upper {
          padding-top: 1.0em;
        }

        .expected {
          font-weight: $ct-ui-font-weight-7;
        }
      }
    }
  }
}
</style>
